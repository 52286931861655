
import {
  defineComponent, ref, toRefs, computed
} from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmTable,
  },
  props: {
    year: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { year } = toRefs(props)

    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Month', value: 'month-slot' },
      { text: 'Sent', value: 'sent' },
      { text: 'Received', value: 'received' },
      { text: 'Delivery rate', value: 'deliveryRate' },
      { text: 'Reply rate', value: 'replyRate' },
      { text: 'Costs', value: 'costs' },
    ])

    const tableItems = computed(() => [
      { monthShort: '01', month: `January ${year.value}`, sent: '1,445', received: '45', deliveryRate: '51.91%', replyRate: '1.62%', costs: '$ 993.00' },
      { monthShort: '02', month: `February ${year.value}`, sent: '2,491', received: '91', deliveryRate: '78.57%', replyRate: '8.13%', costs: '$ 138.00' },
      { monthShort: '03', month: `March ${year.value}`, sent: '1,223', received: '23', deliveryRate: '45.94%', replyRate: '2.52%', costs: '$ 441.00' },
      { monthShort: '04', month: `April ${year.value}`, sent: '1,445', received: '99', deliveryRate: '52.22%', replyRate: '7.37%', costs: '$ 451.00' },
      { monthShort: '05', month: `May ${year.value}`, sent: '999', received: '39', deliveryRate: '24.84%', replyRate: '9.01%', costs: '$ 483.00' },
      { monthShort: '06', month: `June ${year.value}`, sent: '639', received: '26', deliveryRate: '94.08%', replyRate: '4.76%', costs: '$ 180.00' },
      { monthShort: '07', month: `July ${year.value}`, sent: '826', received: '312', deliveryRate: '98.39%', replyRate: '6.42%', costs: '$ 1,670.00' },
      { monthShort: '08', month: `August ${year.value}`, sent: '6,316', received: '93', deliveryRate: '55.68%', replyRate: '6.37%', costs: '$ 448.00' },
      { monthShort: '09', month: `September ${year.value}`, sent: '893', received: '31', deliveryRate: '64.19%', replyRate: '2.97%', costs: '$ 397.00' },
      { monthShort: '10', month: `October ${year.value}`, sent: '757', received: '57', deliveryRate: '11.57%', replyRate: '6.55%', costs: '$ 217.00' },
      { monthShort: '11', month: `November ${year.value}`, sent: '1,445', received: '99', deliveryRate: '52.22%', replyRate: '7.37%', costs: '$ 451.00' },
      { monthShort: '12', month: `December ${year.value}`, sent: '6,316', received: '93', deliveryRate: '55.68%', replyRate: '6.37%', costs: '$ 448.00' },
    ])

    return {
      tableHeaders,
      tableItems,
    }
  },
})
